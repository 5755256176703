<template>
  <base-view id="login-employer">
    <view-header
      title="Employer Login / Registration"
      image="bg-login-employer.jpg"
    >
      What kind of account are you looking for?
    </view-header>
    <view-section>
      <div
        class="flex flex-col justify-between overflow-hidden bg-neutral-light rounded-lg shadow-lg md:flex-row"
      >
        <div
          class="relative flex flex-col w-full p-4 pb-12 text-center text-white bg-primary"
        >
          <h1 class="my-6 text-4xl font-semibold">Benefits Login</h1>
          <p class="flex-1 m-0 mt-4 mb-12">
            Click here for information on your company's health benefits, such
            as an FSA, DCA, HRA, HSA, PKG, TRN, another form of benefit account.
          </p>
          <div>
            <Button
              class="m-2"
              reg
              light
              href="https://www.wealthcareadmin.com/selfservicelogin.aspx?P=ubMbP7DMjWJBIgsiogth42nZrbHDnoFv2p%2fOgmWzHOyeJ%2flXUQDehzO5d%2b1G3oPJ&H=7a639f5ff7e74510c4b5cded4f893cb11c4416afddc6cb83df07e702b23e1789"
            >
              Employer Login
            </Button>
            <Button
              class="m-2"
              reg
              light
              href="https://admin-rmr.elevateaccounts.com/login"
              ><strong><em>New </em></strong>
              Employer Login
            </Button>
          </div>
        </div>
        <div
          class="relative flex flex-col w-full p-4 pb-12 text-center bg-neutral-lighter"
        >
          <h1 class="my-6 text-4xl font-semibold">COBRA Login</h1>
          <p class="flex-1 m-0 mt-4 mb-12">
            COBRA is for former employees continuing their insurance/health
            benefits after termination.
          </p>
          <div>
            <!-- <Button class="m-2" reg href="https://cobrapoint.benaissance.com/"> -->
            <Button
              class="m-2"
              reg
              href="https://cobrapoint.benaissance.com/employerportal/login?state=eyJjYW5jZWxfdXJpIjoiaHR0cHM6Ly9jb2JyYXBvaW50LmJlbmFpc3NhbmNlLmNvbS8iLCJsb2dvdXRfdXJpIjoiaHR0cHM6Ly9jb2JyYXBvaW50LmJlbmFpc3NhbmNlLmNvbS8ifQ%3d%3d"
            >
              COBRA Login
            </Button>
            <Button
              class="m-2"
              reg
              href="https://cobrapoint.benaissance.com/Registration/Identify"
            >
              COBRA Registration
            </Button>
          </div>
        </div>
        <div
          class="relative flex flex-col w-full p-4 pb-12 text-center text-white bg-primary"
        >
          <h1 class="my-6 text-4xl font-semibold">FTP Login</h1>
          <p class="flex-1 m-0 mt-4 mb-12">
            Login here to upload your data safely and securely.
          </p>
          <div>
            <Button class="m-2" reg light href="https://rmr.files.com/login">
              FTP Login
            </Button>
          </div>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import Button from "@/components/ui/Button.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    Button
  }
};
</script>
